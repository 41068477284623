import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { withRouter } from "react-router";
import Home from "../views/Home";
import About from "../views/About";
import OurWork from "../views/OurWork";
import ServicesView from "../views/Services";
import TechnologiesView from "../views/Technologies";
import styled from "styled-components";
import { hideMenu } from "../redux/actions";

const appearDuration = 5000;
const transitionName = `example`;

const TransitionContainer = styled.div`
  &.${transitionName}-appear {
    opacity: 0.01;
  }

  &.${transitionName}-appear-active {
    opacity: 1;
    transition: opacity ${appearDuration}ms ease-out;
  }
`;

class Routes extends Component {
  componentDidUpdate(prevProps) {
    const currMatch = this.props.match;
    const prevMatch = prevProps.match;
    console.log("Update Called", prevProps, this.props);

    //if (!prevMatch || currMatch.location.key !== prevMatch.location.key) {
    //this.props.dispatch(hideMenu());
    //}
  }
  render() {
    const { location } = this.props;
    return (
      <TransitionGroup>
        <CSSTransition
          key={location.key}
          classNames="fade"
          transitionName="example"
          timeout={500}
          transitionLeaveTimeout={100}
        >
          <Switch>
            <Route path="/" exact>
              {({ match }) => {
                console.log("Match = ", match);
                return <Home show={match !== null} />;
              }}
            </Route>

            <Route path="/about-us">
              {({ match }) => {
                console.log("Match = ", match);
                return <About show={match !== null} />;
              }}
            </Route>

            <Route path="/technologies">
              {({ match }) => {
                console.log("Match = ", match);
                return <TechnologiesView show={match !== null} />;
              }}
            </Route>

            <Route path="/services">
              {({ match }) => {
                console.log("Match = ", match);
                return <ServicesView show={match !== null} />;
              }}
            </Route>

            <Route path="/our-work">
              {({ match }) => {
                console.log("Match = ", match);
                return <OurWork show={match !== null} />;
              }}
            </Route>
          </Switch>
        </CSSTransition>
      </TransitionGroup>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    sendTheAlert: () => {
      dispatch();
    }
  };
}

export default withRouter(connect()(Routes));
