import React, { Component } from "react";
import styled from "styled-components";
import anime from "animejs";

const ItemContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 60px;
  text-decoration: none;
  position: relative;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 4px;
  font-size: 15px;

  & svg {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    fill-rule: evenodd;
    stroke: currentColor;
    fill: none;
    transform: scale(1.5);
  }
`;

class MenuItem extends Component {
  constructor() {
    super();
    this.domElement = React.createRef();
  }
  _hover = () => {
    anime.remove([this.pathEl, this.spanEl]);
    anime({
      targets: this.pathEl,
      d:
        "M10,10 C10,10 50,7 90,7 C130,7 170,10 170,10 C170,10 172,20 172,30 C172,40 170,50 170,50 C170,50 130,53 90,53 C50,53 10,50 10,50 C10,50 8,40 8,30 C8,20 10,10 10,10 Z",
      elasticity: 700,
      offset: 0,
    });
    anime({
      targets: this.spanEl,
      scale: 1.15,
      duration: 800,
      offset: 0,
    });
  };
  _down = () => {
    anime.remove([this.pathEl, this.spanEl]);
    anime({
      targets: this.pathEl,
      d:
        "M10,10 C10,10 50,9.98999977 90,9.98999977 C130,9.98999977 170,10 170,10 C170,10 170.009995,20 170.009995,30 C170.009995,40 170,50 170,50 C170,50 130,50.0099983 90,50.0099983 C50,50.0099983 10,50 10,50 C10,50 9.98999977,40 9.98999977,30 C9.98999977,20 10,10 10,10 Z",
      elasticity: 700,
      offset: 0,
    });
    anime({
      targets: this.spanEl,
      scale: 1,
      duration: 800,
      offset: 0,
    });
  };
  componentDidMount() {
    this.pathEl = this.domElement.current.querySelector("path");
    this.spanEl = this.domElement.current.querySelector("span");
  }
  render() {
    return (
      <ItemContainer
        onMouseOver={() => {
          this._hover();
        }}
        onMouseLeave={() => {
          this._down();
        }}
        ref={this.domElement}
      >
        <svg viewBox="0 0 180 60">
          <path
            id="normal"
            d="M10,10 C10,10 50,9.98999977 90,9.98999977 C130,9.98999977 170,10 170,10 C170,10 170.009995,20 170.009995,30 C170.009995,40 170,50 170,50 C170,50 130,50.0099983 90,50.0099983 C50,50.0099983 10,50 10,50 C10,50 9.98999977,40 9.98999977,30 C9.98999977,20 10,10 10,10 Z"
          />
        </svg>
        <span>{this.props.caption}</span>
      </ItemContainer>
    );
  }
}

export default MenuItem;
