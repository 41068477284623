import React from "react";
import {
  FaFacebookF,
  FaLinkedinIn,
  FaTwitter,
  FaInstagram,
  FaYoutube,
  FaGithubAlt,
} from "react-icons/fa";
import styled from "styled-components";
const RSContainer = styled.aside`
  width: 100px;
  width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (orientation: portrait) {
    display: none;
  }
`;
const RSLinksContainer = styled.ul`
  list-style: none;
  li:not(:first-child) {
    margin: 20px 0;
  }
`;
const RightSidebar = (props) => {
  return (
    <RSContainer>
      <RSLinksContainer>
        <li>
          <FaFacebookF />
        </li>
        <li>
          <FaLinkedinIn />
        </li>
        <li>
          <FaTwitter />
        </li>
        <li>
          <FaInstagram />
        </li>
        <li>
          <FaYoutube />
        </li>
        <li>
          <FaGithubAlt />
        </li>
      </RSLinksContainer>
    </RSContainer>
  );
};

export default RightSidebar;
