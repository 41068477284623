const wrapVertexShader = `attribute float size;
attribute vec3 color;
varying vec3 vColor;
void main() {
    vColor = color;
    vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );
    gl_PointSize = size * ( 350.0 / - mvPosition.z );
    gl_Position = projectionMatrix * mvPosition;
}`;

const wrapFragmentShader = `varying vec3 vColor;
uniform sampler2D texture;
void main(){
    vec4 textureColor = texture2D( texture, gl_PointCoord );
    if ( textureColor.a < 0.3 ) discard;
    vec4 color = vec4(vColor.xyz, 1.0) * textureColor;
    gl_FragColor = color;
}`;

export { wrapVertexShader, wrapFragmentShader };
