import * as THREE from "three";
import { TweenMax, Expo, Power2 } from "gsap/TweenMax";
import { vertexShader, fragmentShader } from "./shaders/shaders";

export default canvas => {
  var SCREEN_WIDTH = window.innerWidth;
  var SCREEN_HEIGHT = window.innerHeight;
  var width, height;

  var camera, scene, renderer;

  var mouseX = 0,
    mouseY = 0;

  var windowHalfX = window.innerWidth / 2;
  var windowHalfY = window.innerHeight / 2;

  init();
  animate();

  function init() {
    camera = new THREE.PerspectiveCamera(
      35,
      SCREEN_WIDTH / SCREEN_HEIGHT,
      1,
      5000
    );
    camera.position.z = 1500;

    scene = new THREE.Scene();
    scene.background = new THREE.Color(0x000000);
    scene.fog = new THREE.Fog(0x000000, 1500, 4000);

    // GROUND

    var imageCanvas = document.createElement("canvas"),
      context = imageCanvas.getContext("2d");

    imageCanvas.width = imageCanvas.height = 128;

    context.fillStyle = "#444";
    context.fillRect(0, 0, 128, 128);

    context.fillStyle = "#fff";
    context.fillRect(0, 0, 64, 64);
    context.fillRect(64, 64, 64, 64);

    var textureCanvas = new THREE.CanvasTexture(imageCanvas);
    textureCanvas.repeat.set(1000, 1000);
    textureCanvas.wrapS = THREE.RepeatWrapping;
    textureCanvas.wrapT = THREE.RepeatWrapping;

    var materialCanvas = new THREE.MeshBasicMaterial({
      map: textureCanvas
    });

    var geometry = new THREE.PlaneBufferGeometry(100, 100);

    var meshCanvas = new THREE.Mesh(geometry, materialCanvas);
    meshCanvas.rotation.x = -Math.PI / 2;
    meshCanvas.scale.set(1000, 1000, 1000);

    // PAINTING

    var showTexture = function(texturePainting, object) {
      var image = texturePainting.image;
      var width = 900;
      var height = (image.height / image.width) * width;

      var geometry = new THREE.PlaneBufferGeometry(100, 100);
      var xPos = (SCREEN_WIDTH - width) / 2;
      scene.add(meshCanvas);
      addPainting(scene, object);

      function addPainting(zscene, zmesh) {
        zmesh.scale.x = width / 100;
        zmesh.scale.y = height / 100;

        zscene.add(zmesh);

        var meshFrame = new THREE.Mesh(
          geometry,
          new THREE.MeshBasicMaterial({ color: 0x000000 })
        );
        zmesh.translateX(-xPos);
        meshFrame.position.z = -10.0;
        meshFrame.scale.x = (1.05 * width) / 100;
        meshFrame.scale.y = (1.05 * height) / 100;
        zscene.add(meshFrame);
        meshFrame.translateX(-xPos);

        var meshShadow = new THREE.Mesh(
          geometry,
          new THREE.MeshBasicMaterial({
            color: 0x000000,
            opacity: 0.75,
            transparent: true
          })
        );
        meshShadow.position.y = (-1.1 * height) / 2;
        meshShadow.position.z = (-1.1 * height) / 2;
        meshShadow.rotation.x = -Math.PI / 2;
        meshShadow.scale.x = (1.1 * width) / 100;
        meshShadow.scale.y = (1.1 * height) / 100;
        zscene.add(meshShadow);
        meshShadow.translateX(-xPos);

        var floorHeight = (-1.117 * height) / 2;
        meshCanvas.position.y = floorHeight;
      }
    };

    var images = [
      require("./images/bm1.png"),
      require("./images/bm2.png"),
      require("./images/bm3.png")
    ];
    var textures = images.map(image => new THREE.TextureLoader().load(image));
    var displacementFileNames = [
      require("./images/displacement/1.jpg"),
      require("./images/displacement/2.jpg"),
      require("./images/displacement/3.jpg"),
      require("./images/displacement/4.png"),
      require("./images/displacement/5.png"),
      require("./images/displacement/6.jpg"),
      require("./images/displacement/7.jpg"),
      require("./images/displacement/8.jpg"),
      require("./images/displacement/9.jpg"),
      require("./images/displacement/10.jpg"),
      require("./images/displacement/11.jpg"),
      require("./images/displacement/12.jpg"),
      require("./images/displacement/13.jpg"),
      require("./images/displacement/14.jpg"),
      require("./images/displacement/15.jpg"),
      require("./images/displacement/16.jpg")
    ];
    var displacementTexture = new THREE.TextureLoader().load(
      displacementFileNames[Math.floor(Math.random() * 16)]
    );
    displacementTexture.wrapS = displacementTexture.wrapT =
      THREE.RepeatWrapping;

    var currSlide = 0;
    var prevSlide = textures.length - 1;
    var changeSlides = () => {
      var mat = new THREE.ShaderMaterial({
        uniforms: {
          effectFactor: { type: "f", value: 0.8 },
          dispFactor: { type: "f", value: 0.0 },
          texture: { type: "t", value: textures[prevSlide] },
          texture2: { type: "t", value: textures[currSlide] },
          disp: { type: "t", value: displacementTexture }
        },

        vertexShader: vertexShader,
        fragmentShader: fragmentShader,
        transparent: true,
        opacity: 1.0
      });

      var geometry = new THREE.PlaneBufferGeometry(100, 100, 1);

      var object = new THREE.Mesh(geometry, mat);
      scene = new THREE.Scene();

      //scene.add(object);

      scene.background = new THREE.Color(0x000000);
      scene.fog = new THREE.Fog(0x000000, 1500, 4000);
      showTexture(textures[currSlide], object);
      TweenMax.to(mat.uniforms.dispFactor, 1, {
        value: 1,
        ease: Expo.easeOut
      });

      setTimeout(() => {
        prevSlide = currSlide;
        currSlide++;

        if (currSlide >= textures.length) {
          currSlide = 0;
          prevSlide = textures.length - 1;
        }
        changeSlides();
      }, 3000);
    };

    renderer = new THREE.WebGLRenderer({ canvas, antialias: true });
    renderer.setPixelRatio(window.devicePixelRatio);
    onResize();
    renderer.autoClear = false;

    // renderer.domElement.style.position = "relative";
    // container.appendChild(renderer.domElement);

    document.addEventListener("mousemove", onDocumentMouseMove, false);
    setTimeout(() => {
      changeSlides();
    }, 1000);
  }

  function onDocumentMouseMove(event) {
    mouseX = event.clientX - windowHalfX;
    mouseY = event.clientY - windowHalfY;
  }

  function animate() {
    render();
  }

  function render() {
    camera.position.x += (mouseX - camera.position.x) * 0.05;
    camera.position.y += (-(mouseY - 200) - camera.position.y) * 0.05;
    camera.lookAt(scene.position);
    renderer.render(scene, camera);
  }

  function onResize() {
    // canvas.style.width = "";
    // canvas.style.height = "";
    width = canvas.offsetWidth;
    height = canvas.offsetHeight;
    camera.aspect = width / height;
    camera.updateProjectionMatrix();
    renderer.setSize(width, height);
  }

  return {
    render: animate,
    onResize
  };
};
