import * as THREE from "three";
import { TweenMax, Expo, Power2 } from "gsap/TweenMax";
import { vertexShader, fragmentShader } from "./shaders/shaders";

export default (canvas) => {
  var SCREEN_WIDTH = window.innerWidth;
  var SCREEN_HEIGHT = window.innerHeight;
  var width, height;

  var camera, scene, renderer;

  var mouseX = 0,
    mouseY = 0;

  var windowHalfX = window.innerWidth / 2;
  var windowHalfY = window.innerHeight / 2;

  init();
  animate();

  function init() {
    camera = new THREE.PerspectiveCamera(
      35,
      SCREEN_WIDTH / SCREEN_HEIGHT,
      1,
      5000
    );
    camera.position.z = 1500;

    scene = new THREE.Scene();

    var showTexture = function(texturePainting, object) {
      if (
        texturePainting === undefined ||
        texturePainting.image === undefined
      ) {
        return;
      }
      var image = texturePainting.image;
      var width = SCREEN_WIDTH + SCREEN_WIDTH * 0.2;
      var height = (image.height / image.width) * width;
      object.scale.x = width / 100;
      object.scale.y = height / 100;
      scene.add(object);
    };

    var images = [
      require("./images/ai-v2.jpg"),
      require("./images/vr.jpg"),
      require("./images/mob.jpg"),
      require("./images/web.jpg"),
      require("./images/ar.jpg"),
      require("./images/vr-2.jpg"),
    ];
    var textures = images.map((image) => new THREE.TextureLoader().load(image));
    var displacementFileNames = [
      require("./images/displacement/1.jpg"),
      require("./images/displacement/2.jpg"),
      require("./images/displacement/3.jpg"),
      require("./images/displacement/4.png"),
      require("./images/displacement/5.png"),
      require("./images/displacement/6.jpg"),
      require("./images/displacement/7.jpg"),
      require("./images/displacement/8.jpg"),
      require("./images/displacement/9.jpg"),
      require("./images/displacement/10.jpg"),
      require("./images/displacement/11.jpg"),
      require("./images/displacement/12.jpg"),
      require("./images/displacement/13.jpg"),
      require("./images/displacement/14.jpg"),
      require("./images/displacement/15.jpg"),
      require("./images/displacement/16.jpg"),
      require("./images/displacement/17.jpg"),
      require("./images/displacement/18.jpg"),
      require("./images/displacement/19.png"),
      require("./images/displacement/20.jpeg"),
      require("./images/displacement/21.jpg"),
      require("./images/displacement/22.jpg"),
    ];
    var displacementTexture = new THREE.TextureLoader().load(
      displacementFileNames[
        Math.floor(Math.random() * displacementFileNames.length)
      ]
    );
    displacementTexture.wrapS = displacementTexture.wrapT =
      THREE.RepeatWrapping;

    var currSlide = 0;
    var prevSlide = textures.length - 1;
    var changeSlides = () => {
      var mat = new THREE.ShaderMaterial({
        uniforms: {
          effectFactor: { type: "f", value: 0.8 },
          dispFactor: { type: "f", value: 0.0 },
          texture: { type: "t", value: textures[prevSlide] },
          texture2: { type: "t", value: textures[currSlide] },
          disp: { type: "t", value: displacementTexture },
        },

        vertexShader: vertexShader,
        fragmentShader: fragmentShader,
        transparent: true,
        opacity: 1.0,
      });

      var geometry = new THREE.PlaneBufferGeometry(100, 100, 1);

      var object = new THREE.Mesh(geometry, mat);
      scene = new THREE.Scene();

      showTexture(textures[currSlide], object);
      TweenMax.to(mat.uniforms.dispFactor, 1, {
        value: 1,
        ease: Expo.easeOut,
      });

      setTimeout(() => {
        prevSlide = currSlide;
        currSlide++;

        if (currSlide >= textures.length) {
          currSlide = 0;
          prevSlide = textures.length - 1;
        }
        changeSlides();
      }, 3000);
    };

    renderer = new THREE.WebGLRenderer({ canvas, antialias: true });
    renderer.setPixelRatio(window.devicePixelRatio);
    onResize();
    renderer.autoClear = false;

    document.addEventListener("mousemove", onDocumentMouseMove, false);
    setTimeout(() => {
      changeSlides();
    }, 1000);
  }

  function onDocumentMouseMove(event) {
    mouseX = event.clientX - windowHalfX;
    mouseY = event.clientY - windowHalfY;
  }

  function animate() {
    render();
  }

  function render() {
    camera.position.x += (-(mouseX * 0.25) - camera.position.x) * 0.05;
    camera.position.y += (-(mouseY * 0.25) - camera.position.y) * 0.05;
    camera.lookAt(scene.position);
    renderer.render(scene, camera);
  }

  function onResize() {
    // canvas.style.width = "";
    // canvas.style.height = "";
    width = canvas.offsetWidth;
    height = canvas.offsetHeight;
    camera.aspect = width / height;
    camera.updateProjectionMatrix();
    renderer.setSize(width, height);
  }

  return {
    render: animate,
    onResize,
  };
};
