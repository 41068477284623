import React, { PureComponent } from "react";
import styled, { keyframes } from "styled-components";
import { Link } from "react-router-dom";
import MenuAnimation from "../animations/MenuAnimation/MenuAnimation";
import { TimelineMax, Elastic, Expo } from "gsap/all";
import { Transition } from "react-transition-group";
import MenuItem from "./MenuItem";

const highlighterAnim = keyframes`
  50% {
    transform:translateY(50vh);
    height: 300px;
  }

  100% {
    transform:translateY(100vh);
    height: 0px;
  }
`;

const MenuContainer = styled.div`
  position: absolute;
  z-index: 10;
  width: 100%;
  top: 0;
  left: 0;
  height: 0;
  overflow: hidden;

  & .background {
    height: 0%;
    overflow: hidden;
    position: relative;
  }

  & .highlighter {
    height: 0px;
    width: 100%;
    background-color: red;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 12;
  }

  &.visible .highlighter {
    animation-direction: alternate;
    animation: 1s ${highlighterAnim} linear backwards;
  }
`;
const MenuDetailHolder = styled.div`
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: stretch;
`;
const MenuListHolder = styled.div`
  width: 70%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  & nav {
    display: flex;
    flex-direction: column;
    align-items: center;

    & a {
      padding: 15px;
      font-size: 30px;
      text-decoration: none;
      opacity: 1;
      perspective: 600;
    }
  }
`;

const ContactInfoHolder = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  color: #fff;
`;

class MenuPage extends PureComponent {
  constructor() {
    super();
    this.domElement = React.createRef();
    this.menuTween = new TimelineMax({ pause: true });
  }

  componentDidMount() {
    const { showMenu } = this.props;
    const mainDiv = this.domElement.current;
    const backgroundDiv = mainDiv.querySelector(".background");
    const navElements = mainDiv.querySelectorAll("nav a");
    const address = mainDiv.querySelector("address");
    const highlighterDiv = mainDiv.querySelector(".highlighter");
    console.log(mainDiv, backgroundDiv, navElements);
    this.menuTween
      .to(mainDiv, 0.7, {
        height: "100%",
        ease: Expo.easeOut,
      })
      .to(
        backgroundDiv,
        0.8,
        {
          height: "100%",
        },
        "-=0.4"
      )
      .staggerFrom(
        navElements,
        2,
        {
          cycle: {
            x: (i) => -(100 + i * 50),
          },
          //rotationX: -180,
          transformOrigin: "left top",
          ease: Elastic.easeOut.config(1, 0.3),
          autoAlpha: showMenu ? 1 : 0,
        },
        0.1,
        "-=0.5"
      )
      .from(
        address,
        1,
        {
          y: 100,
          autoAlpha: showMenu ? 1 : 0,
          ease: Elastic.easeOut.config(1, 1),
        },
        "-=2"
      )
      .pause();
    if (showMenu) {
      this.menuTween.play();
    } else {
      this.menuTween.reverse();
    }
  }

  componentDidUpdate(prevState) {
    console.log("Did update called");
    const { showMenu } = this.props;
    if (showMenu) {
      this.menuTween.timeScale(1).play();
    } else {
      this.menuTween.timeScale(1).reverse(-1);
    }
  }

  render() {
    const { showMenu } = this.props;
    const className = showMenu ? "visible" : "";
    return (
      <MenuContainer ref={this.domElement} className={className}>
        <div className="background">
          <div className="highlighter" />
          <MenuAnimation animate={showMenu} />
          <MenuDetailHolder>
            <MenuListHolder>
              <nav>
                <Link
                  to="/"
                  onClick={() => {
                    this.props.onClick("/");
                  }}
                >
                  <MenuItem caption="Home" />
                </Link>
                <Link
                  to="/about-us"
                  onClick={() => {
                    this.props.onClick("/about-us");
                  }}
                >
                  <MenuItem caption="About Us" />
                </Link>
                <Link
                  to="/services"
                  onClick={() => {
                    this.props.onClick("/services");
                  }}
                >
                  <MenuItem caption="Services" />
                </Link>
                <Link
                  to="/technologies"
                  onClick={() => {
                    this.props.onClick("/technologies");
                  }}
                >
                  <MenuItem caption="Technologies" />
                </Link>
                <Link
                  to="/our-work"
                  onClick={() => {
                    this.props.onClick("/our-work");
                  }}
                >
                  <MenuItem caption="Our Work" />
                </Link>
                <Link
                  to="/our-work"
                  onClick={() => {
                    this.props.onClick("/our-work");
                  }}
                >
                  <MenuItem caption="Our Clients" />
                </Link>
                <Link
                  to="/contact-us"
                  onClick={() => {
                    this.props.onClick("/contact-us");
                  }}
                >
                  <MenuItem caption="Contact Us" />
                </Link>
              </nav>
            </MenuListHolder>
            <ContactInfoHolder>
              <address>
                <h3>Q Dev Digital Pvt Ltd</h3>
                <div>
                  Amstor Building, <br />
                  Fourth Floor,
                  <br />
                  Technopark,
                  <br />
                  Kazhakkoottam
                </div>
              </address>
            </ContactInfoHolder>
          </MenuDetailHolder>
        </div>
      </MenuContainer>
    );
  }
}
export default MenuPage;
