import SceneManager from "./SceneManager";

export default container => {
  const canvas = createCanvas(document, container);
  const sceneManager = new SceneManager(canvas);

  let animationFrameHandle = null;

  bindEventListeners();

  function createCanvas(document, container) {
    const canvas = document.createElement("canvas");
    container.appendChild(canvas);
    return canvas;
  }

  function bindEventListeners() {
    window.addEventListener("resize", resizeCanvas, false);
    resizeCanvas();
  }

  function resizeCanvas() {
    canvas.style.width = "100%";
    canvas.style.height = "100%";

    canvas.width = canvas.offsetWidth;
    canvas.height = canvas.offsetHeight;

    sceneManager.onResize();
  }

  function render(time) {
    sceneManager.render();
    animationFrameHandle = requestAnimationFrame(render);
  }

  function startAnimation() {
    stopAnimation();
    sceneManager.render();
    render();
  }

  function stopAnimation() {
    if (animationFrameHandle) {
      cancelAnimationFrame(animationFrameHandle);
      console.log("Stopping Home animation");
    }
  }

  function unload() {
    cancelAnimationFrame(animationFrameHandle);
  }
  return {
    render,
    startAnimation,
    stopAnimation,
    unload
  };
};
