import { SHOW_MENU, HIDE_MENU } from "../actionTypes";

const initialState = {
  showMenu: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SHOW_MENU: {
      return {
        showMenu: true
      };
    }
    case HIDE_MENU: {
      return {
        showMenu: false
      };
    }
    default:
      return state;
  }
};
