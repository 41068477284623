import React from "react";
import HomeAnimation from "../components/animations/HomeAnimation/HomeAnimation";
import styled, { keyframes } from "styled-components";
import { TweenMax } from "gsap/all";
import { Transition } from "react-transition-group";
import PerspectiveContent from "../components/PerspectiveContent/PerspectiveContent";

const TitleHolder = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
  z-index: 2;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const flicker = keyframes`
  50% {
    color: white;
    filter: saturate(200%) hue-rotate(20deg);
  }
`;

// const Heading = styled.h1`
//   font-family: "Playfair Display", serif;
//   font-size: 10vw;
//   font-weight: normal;
//   color: rgb(255, 255, 255);
//   text-align: center;
//   pointer-events: none;
//   margin: 0px;
// `;

const bgImages = [
  require("./images/bg1.jpg"),
  require("./images/bg2.jpg"),
  require("./images/bg4.jpg"),
];

const Heading = styled.h1`
  font-family: "Oswald", sans-serif;
  font-weight: 700;
  color: transparent;
  font-size: 10rem;
  background: url(${
    bgImages[Math.floor(Math.random() * bgImages.length)]
  }) repeat;
  background-position: 40% 50%;
  -webkit-background-clip: text;
  position: relative;
  text-align: center;
  line-height: 10rem;
  letter-spacing: -0.8rem;
  padding: 2rem;
  margin: 0;
  text-align:center;
  @media (orientation: portrait) {
    font-size: 6rem;
    line-height: 6rem;
    letter-spacing: -0.2rem;
  }
}
`;

const SubHeading = styled.h2`
  font-size: 3rem;
  color: rgb(255, 255, 255);
  line-height: 1;
  /* letter-spacing: 0.5rem; */
  text-indent: 0.5rem;
  font-weight: bold;
  text-transform: uppercase;
  background: rgb(196, 93, 53);
  padding: 0.45rem 0.5rem;
  margin: 0px;
  font-family: "Molle", cursive;
  text-transform: none;
  background: #ca4246;
  background-color: #ca4246;
  box-shadow: 0px 20px 35px -16px rgba(0, 111, 255, 0.65);
  background: linear-gradient(to right, #6a3093, #a044ff);
  padding: 10px 20px;
  text-align: center;
  @media (orientation: portrait) {
    font-size: 1.8rem;
    line-height: 1.8rem;
  }
`;

// const SubHeading = styled.h2`
//   --interval: 2s;
//   color: lightpink;
//   --color1: pink;
//   --color2: orangered;
//   --color3: red;
//   --color4: magenta;
//   display: block;
//   text-shadow: 0 0 10px var(--color1), 0 0 20px var(--color2),
//     0 0 40px var(--color3), 0 0 80px var(--color4);
//   will-change: filter, color;
//   filter: saturate(60%);
//   animation: ${flicker} steps(100) var(--interval) 3s infinite;
//   font-size: 3rem;
//   font-family: "Molle", cursive;
//   padding: 0.45rem 0.5rem;
//   text-align: center;
// `;

const startState = { autoAlpha: 0, y: -50 };

const Home = (props) => {
  return (
    <div>
      <TitleHolder>
        <Transition
          timeout={1000}
          mountOnEnter
          in={true}
          appear
          addEndListener={(node, done) => {
            TweenMax.from(node, 0.5, {
              y: -100,
              autoAlpha: 0,
              onComplete: done,
              delay: 2,
            });

            document.addEventListener("mousemove", (e) => {
              const mouseX = e.pageX;
              const mouseY = e.pageY;
              const traX = (4 * mouseX) / 570 + 40;
              const traY = (4 * mouseY) / 570 + 50;
              node.style.backgroundPosition = traX + "%" + traY + "%";
            });
          }}
        >
          <>
            <Heading>Q-DEV Digital</Heading>
          </>
        </Transition>
        <Transition
          timeout={1000}
          mountOnEnter
          in={true}
          appear
          addEndListener={(node, done) => {
            TweenMax.from(node, 0.5, {
              x: 200,
              autoAlpha: 0,
              onComplete: done,
              delay: 2,
            });
          }}
        >
          <SubHeading>Solutions for complex requirements</SubHeading>
        </Transition>
      </TitleHolder>

      <HomeAnimation />
    </div>
  );
};

export default Home;
