import React from "react";
import TechnologiesAnimation from "../components/animations/TechnologiesAnimation/TechnologiesAnimation";
import styled from "styled-components";
import { TweenMax } from "gsap/all";
import { Transition } from "react-transition-group";

const ServicesHolder = styled.div`
  position: absolute;
  width: 100%;
  height: 100vh;
  /* background-color: green;
  opacity: 0.4; */
  color: white;
`;

const CircleShape = styled.div`
  float: left;
  shape-outside: circle(25%);
  width: 50%;
  height: 100vh;
  shape-margin: 20px;
`;

const Heading = styled.h1``;

const Description = styled.div``;

const Technologies = props => {
  return (
    <div>
      <ServicesHolder>
        <CircleShape />
        <Heading>Technologies</Heading>
        <Description>
          <p>
            Technology is our passion. We work with the latest and the best
            technologies, frameworks, languages and libraries - Well, we even
            create them and share with the world.
          </p>
        </Description>
      </ServicesHolder>
      <TechnologiesAnimation />
    </div>
  );
};

export default Technologies;
