import React, { Component } from "react";
import MenuPage from "../components/Menu/MenuPage";
import { connect } from "react-redux";
import { hideMenu } from "../redux/actions";

class MenuPageContainer extends Component {
  render() {
    const { showMenu } = this.props.menu;
    return (
      <MenuPage
        showMenu={showMenu}
        onClick={menuItem => {
          console.log("Item", menuItem);
          this.props.dispatch(hideMenu());
        }}
      />
    );
  }
}

const mapStateToProps = state => {
  console.log("STATE", state);
  return {
    menu: state.menu
  };
};

export default connect(mapStateToProps)(MenuPageContainer);
