import React from "react";
import styled from "styled-components";

const menuProps = {
  color: "#000",
  height: 30,
  width: 50,
  heightLine: 2,
  transitionTime: 0.4,
  rotation: 45,
  translateY: 30 / 2,
  translateX: 0,
};

const {
  color,
  height,
  width,
  heightLine,
  transitionTime,
  rotation,
  translateX,
  translateY,
} = menuProps;

const HamburgerMenu = styled.a`
  width: ${width}px;
  height: ${height}px;
  position: relative;
  display: block;
  margin: ${width * 2}px auto ${height}px auto;
  transform: rotate(-90deg) translateX(50px);

  .line {
    display: block;
    background: ${color};
    width: ${width}px;
    height: ${heightLine}px;
    position: absolute;
    left: 0;
    border-radius: ${height / 2}px;
    transition: all ${transitionTime}s;

    &.line-1 {
      top: 0;
    }
    &.line-2 {
      top: 50%;
      width: 60%;
    }
    &.line-3 {
      top: 100%;
    }
  }
  &:hover,
  &:focus {
    .line-1 {
      transform: translateY(${(heightLine / 2) * -1}px);
    }
    .line-2 {
      transform: translateX(${(width / 2) * 1}px);
    }
    .line-3 {
      transform: translateY(${heightLine / 2}px);
    }
  }
  &.active {
    .line-1 {
      transform: translateY(${translateY}px) translateX(${translateX}px)
        rotate(${rotation}deg);
    }
    .line-2 {
      opacity: 0;
    }
    .line-3 {
      transform: translateY(${translateY * -1}px) translateX(${translateX}px)
        rotate(${rotation * -1}deg);
    }
  }
`;

const MenuContainer = styled.span`
  display: block;
  text-align: center;
  font-size: 12px;
  transform: rotate(-90deg);
`;

const MenuLink = (props) => {
  return (
    <div>
      <MenuContainer>LAUNCHING SOON</MenuContainer>
    </div>
  );
};

export default MenuLink;
