import React, { Component } from "react";

import animationHandler from "./three/animationHandler";

class AboutAnimation extends Component {
  constructor(props) {
    super(props);
    this.animationLoaded = false;
    this.animationHandler = null;
  }
  componentDidMount() {
    this.animationHandler = animationHandler(this.threeRootElement);
    this.animationLoaded = true;
    this.animationHandler.startAnimation();
    console.log("About animation mounted");
  }

  componentWillUnmount() {
    console.log("About animation unmounted");
    this.animationHandler.stopAnimation();
  }

  render() {
    return (
      <div>
        <div
          className="about-animation"
          style={{ width: "100%", height: "100vh" }}
          ref={element => (this.threeRootElement = element)}
        />
      </div>
    );
  }
}

export default AboutAnimation;
