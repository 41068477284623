import React, { PureComponent } from "react";

import animationHandler from "./three/animationHandler";

class HomeAnimation extends PureComponent {
  constructor(props) {
    super(props);
    this.animationLoaded = false;
    this.animationHandler = null;
  }
  componentDidMount() {
    this.animationHandler = animationHandler(this.threeRootElement);
    this.animationLoaded = true;
    this.animationHandler.startAnimation();
    console.log("Home animation mounted");
  }

  componentWillUnmount() {
    this.animationHandler.stopAnimation();
    console.log("Home animation unmounted");
  }

  render() {
    return (
      <div
        className="home-animation"
        style={{ width: "100%", height: "100vh" }}
        ref={element => (this.threeRootElement = element)}
      />
    );
  }
}

export default HomeAnimation;
