import * as THREE from "three";
import { TweenMax, Power0, Power2 } from "gsap/TweenMax";
import dotImage from "./images/dotTexture.png";
//import Stats from "../../stats/stats";

export default (canvas) => {
  var SEPARATION = 120,
    AMOUNTX = 300,
    AMOUNTY = 70;

  var container, stats;
  var camera, scene, renderer, controls;

  var particles,
    particle,
    count = 0;

  var mouseX = 0,
    mouseY = 0;

  var windowHalfX = window.innerWidth / 2;
  var windowHalfY = window.innerHeight / 2;
  var cameraMax = { x: 100, y: 340 };
  var cameraMin = { x: -100, y: 360 };

  const cameraMaxChange = { x: 100, y: 20 };
  const cameraRequired = {
    x: 0,
    y: 355,
    z: 1000,
  };

  const cameraInitial = {
    x: 0,
    y: 355,
    z: 100,
  };

  init();
  animate();

  function getRandomColor() {
    const colors = [0x7b1fa2, 0x512da8, 0xe91e63, 0xe040fb, 0x448aff, 0x8bc34a];
    return colors[Math.floor(Math.random() * (colors.length + 1))];
  }

  function init() {
    // container = document.createElement('div');
    // document.body.appendChild(container);

    camera = new THREE.PerspectiveCamera(
      75,
      window.innerWidth / window.innerHeight,
      1,
      10000
    );
    //camera.position.z = 1000;
    // camera.position.y = 140;
    // camera.position.x = 20;

    // const { x, y, z } = cameraInitial;
    camera.position.set(0, 355, 1000);

    scene = new THREE.Scene();

    particles = [];

    //var PI2 = Math.PI * 2;
    // var material = new THREE.SpriteCanvasMaterial({

    // 	color: 0xffffff,
    // 	program: function (context) {

    // 		context.beginPath();
    // 		context.arc(0.9, 0, 0.5, 0, PI2, true);
    // 		context.fill();

    // 	}

    // });

    var spriteMap = new THREE.TextureLoader().load(dotImage);
    // var material = new THREE.SpriteMaterial({
    //   map: spriteMap,
    //   color: getRandomColor(),
    // });
    //var sprite = new THREE.Sprite(spriteMaterial);
    //scene.add(sprite);

    var i = 0;

    for (var ix = 0; ix < AMOUNTX; ix++) {
      for (var iy = 0; iy < AMOUNTY; iy++) {
        var material = new THREE.SpriteMaterial({
          map: spriteMap,
          color: getRandomColor(),
        });
        particle = particles[i++] = new THREE.Sprite(material);
        particle.position.x = ix * SEPARATION - (AMOUNTX * SEPARATION) / 2;
        particle.position.z = iy * SEPARATION - (AMOUNTY * SEPARATION) / 2;
        scene.add(particle);
      }
    }

    renderer = new THREE.WebGLRenderer({ canvas });
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(window.innerWidth, window.innerHeight);
    //container.appendChild(renderer.domElement);

    // stats = new Stats();
    // stats.domElement.style.position = "absolute";
    // stats.domElement.style.top = "0px";
    // document.body.appendChild(stats.domElement);

    document.addEventListener("mousemove", onDocumentMouseMove, false);
    document.addEventListener("touchstart", onDocumentTouchStart, false);
    document.addEventListener("touchmove", onDocumentTouchMove, false);

    //

    //window.addEventListener("resize", onWindowResize, false);
  }

  function onWindowResize() {
    let width = canvas.offsetWidth;
    let height = canvas.offsetHeight;
    camera.aspect = width / height;
    camera.updateProjectionMatrix();
    renderer.setSize(width, height);
  }

  function onDocumentMouseMove(event) {
    mouseX = event.clientX;
    mouseY = event.clientY;
  }

  function onDocumentTouchStart(event) {
    if (event.touches.length === 1) {
      event.preventDefault();

      mouseX = event.touches[0].pageX;
      mouseY = event.touches[0].pageY;
    }
  }

  function onDocumentTouchMove(event) {
    if (event.touches.length === 1) {
      event.preventDefault();

      mouseX = event.touches[0].pageX;
      mouseY = event.touches[0].pageY;
    }
  }

  //

  //

  function animate() {
    //requestAnimationFrame(animate);

    render();
    //stats.update();
  }

  function render() {
    let cameraX, cameraY;

    cameraX = (cameraMax.x / window.innerWidth) * mouseX;

    cameraY = (cameraMax.y / window.innerHeight) * mouseY;

    //console.log(cameraX, cameraY);
    // camera.position.x += (mouseX - camera.position.x) * 0.005;
    // camera.position.y += (-mouseY - camera.position.y) * 0.0005;

    camera.position.x = cameraX;
    //camera.position.y = cameraY;
    camera.lookAt(scene.position);

    var i = 0;
    for (var ix = 0; ix < AMOUNTX; ix++) {
      for (var iy = 0; iy < AMOUNTY; iy++) {
        particle = particles[i++];
        particle.position.y =
          Math.sin((ix + count) * 0.3) * 50 + Math.sin((iy + count) * 0.5) * 50;
        particle.scale.x = particle.scale.y =
          (Math.sin((ix + count) * 0.3) + 1) * 4 +
          (Math.sin((iy + count) * 0.5) + 1) * 4;
      }
    }
    // var theta = 1;
    // var radius = 1;
    // camera.position.x = radius * Math.sin(THREE.Math.degToRad(theta));
    // camera.position.y = radius * Math.sin(THREE.Math.degToRad(theta));
    // camera.position.z = radius * Math.cos(THREE.Math.degToRad(theta));
    // camera.lookAt(scene.position);
    // camera.updateMatrixWorld();

    //camera.lookAt(scene.position);
    renderer.render(scene, camera);

    count += 0.1;
  }

  function onEntryAnimation() {
    let tweenCamera = { ...cameraInitial };
    const { x, y, z } = cameraRequired;
    TweenMax.to(tweenCamera, 1, {
      x: x,
      y: y,
      z: z,
      delay: 0.05,
      onComplete: function() {
        //console.log("Complete", tweenCamera);
      },
      onUpdate: () => {
        //console.log("Update", tweenCamera);
        const { x, y, z } = tweenCamera;
        //camera.position.set(x, y, z);
        camera.position.z = z;
      },
      ease: Power0.easeNone,
    });
  }

  return {
    render: animate,
    onResize: onWindowResize,
    onMouseMove: null,
    onEntryAnimation,
  };
};
