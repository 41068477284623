import SceneManager from "./SceneManager";

export default container => {
  const canvas = createCanvas(document, container);
  const sceneManager = new SceneManager(canvas);

  let canvasHalfWidth;
  let canvasHalfHeight;
  let animationFrameHandle = null;

  bindEventListeners();

  function createCanvas(document, container) {
    const canvas = document.createElement("canvas");
    container.appendChild(canvas);
    return canvas;
  }

  function bindEventListeners() {
    window.onresize = resizeCanvas;
    window.onmousemove = mouseMove;
    resizeCanvas();
  }

  function resizeCanvas() {
    canvas.style.width = "100%";
    canvas.style.height = "100vh";

    canvas.width = canvas.offsetWidth;
    canvas.height = canvas.offsetHeight;

    canvasHalfWidth = Math.round(canvas.offsetWidth / 2);
    canvasHalfHeight = Math.round(canvas.offsetHeight / 2);

    sceneManager.onResize();
  }

  function mouseMove(event) {
    //sceneManager.onMouseMove(event);
  }

  function render(time) {
    sceneManager.render();
    animationFrameHandle = requestAnimationFrame(render);
  }

  function startAnimation() {
    //Stop any existing animation
    stopAnimation();
    sceneManager.render();
    sceneManager.onEntryAnimation();
    render();
  }

  function stopAnimation() {
    console.log("Stopping About Animation");
    if (animationFrameHandle) {
      cancelAnimationFrame(animationFrameHandle);
    }
  }

  function unload() {
    cancelAnimationFrame(animationFrameHandle);
  }
  return {
    render,
    startAnimation,
    stopAnimation,
    unload
  };
};
