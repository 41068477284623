import React from "react";
import { Transition } from "react-transition-group";
import { TweenMax } from "gsap/all";
import SlidesAnimation from "../components/animations/SlidesAnimation/SlidesAnimation";
//import styled from "styled-components";

const startState = { autoAlpha: 0, y: -50 };
const OurWork = props => {
  return <SlidesAnimation />;
};

export default OurWork;
