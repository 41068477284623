import React, { Component } from "react";
import MenuLink from "../components/Menu/MenuLink";
import { connect } from "react-redux";
import { showMenu, hideMenu } from "../redux/actions";

class MenuLinkContainer extends Component {
  render() {
    const { menu } = this.props;
    return (
      <MenuLink
        active={menu.showMenu}
        onClick={event => {
          const action = !menu.showMenu ? showMenu : hideMenu;
          this.props.dispatch(action());
        }}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    menu: state.menu
  };
};

export default connect(mapStateToProps)(MenuLinkContainer);
