import React from "react";
import Tilt from "react-parallax-tilt";
import styled from "styled-components";
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";

const ContentHolder = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
  z-index: 2;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const Content = styled.div`
  font-size: 2em;
  opacity: 0.95;
  width: 90%;
  max-width: 900px;
  position: relative;
  border: 1px solid;
  border-image: conic-gradient(red, yellow, lime, aqua, blue, magenta, red) 1;
  ${"" /* background-color: #923cb5;
  background-image: linear-gradient(147deg, #923cb5 0%, #000000 74%); */} background-color: #000000;
  background-image: linear-gradient(147deg, #000000 0%, #434343 74%);
  color: #fff;
  box-shadow: 0 2.8px 2.2px rgba(255, 64, 128, 0.034),
    0 6.7px 5.3px rgba(255, 64, 128, 0.048),
    0 12.5px 10px rgba(255, 64, 128, 0.06),
    0 22.3px 17.9px rgba(255, 64, 128, 0.072),
    0 41.8px 33.4px rgba(255, 64, 128, 0.086),
    0 100px 80px rgba(255, 64, 128, 0.12);

  transform-style: preserve-3d;
  transform: perspective(1000px);
  height: 400px;

  .content {
    padding: 20px 51px 163px 40px;
    background: url(${require("./mountain-range.png")}) repeat-x;
  }

  .parallax-effect-img {
    transform-style: preserve-3d;
    transform: perspective(1000px);
    background-image: url("./background.jpg");
    background-size: contain;
    background-repeat: no-repeat;
  }

  .inner-element {
    transform: translateZ(40px) scale(0.8);
    width: 70%;
    margin-left: 25%;
  }

  h1: {
    text-align: center;
  }
`;

function PerspectiveContent(props) {
  return (
    <ContentHolder>
      <Content>
        <Tilt
          tiltMaxAngleX={10}
          tiltMaxAngleY={10}
          perspective={800}
          transitionSpeed={1500}
          scale={1.1}
          gyroscope={true}
          className="parallax-effect-img"
        >
          <div className="inner-element">
            <img src={require("./tree.png")} />
          </div>
        </Tilt>
      </Content>
    </ContentHolder>
  );
}

export default PerspectiveContent;
