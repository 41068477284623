import * as THREE from "three";
import Stats from "../../stats/stats";
import * as TWEEN from "@tweenjs/tween.js";
import { CSS3DObject, CSS3DRenderer } from "three-css3drenderer";
import MouseControls from "./MouseControls";

import php from "./images/php.png";
import css3 from "./images/css3.png";
import angular from "./images/angular.png";
import html5 from "./images/html5.png";
import mysql from "./images/mysql.png";
import pgsql from "./images/pgsql.png";
import node from "./images/node.png";
import materialui from "./images/materialui.png";
import magento from "./images/magento.png";
import bootstrap from "./images/bootstrap.png";
import jquery from "./images/jQuery.gif";
import laravel from "./images/laravel.png";
import react from "./images/react.png";
import javascript from "./images/javascript.png";
import webgl from "./images/webgl.png";
import ios from "./images/ios.png";
import android from "./images/android.png";
import aws from "./images/aws.png";
import pixijs from "./images/pixijs.png";
import swift from "./images/swift.png";
import symfony from "./images/symfony.png";
import wordpress from "./images/wordpress.png";
import zend from "./images/zend.png";
import googlecloud from "./images/googlecloud.png";
import phaser from "./images/phaser.png";

export default (canvas) => {
  var table = [
    "H",
    "Hydrogen",
    "1.00794",
    1,
    1,
    "He",
    "Helium",
    "4.002602",
    18,
    1,
    "Li",
    "Lithium",
    "6.941",
    1,
    2,
    "Be",
    "Beryllium",
    "9.012182",
    2,
    2,
    "B",
    "Boron",
    "10.811",
    13,
    2,
    "C",
    "Carbon",
    "12.0107",
    14,
    2,
    "N",
    "Nitrogen",
    "14.0067",
    15,
    2,
    "O",
    "Oxygen",
    "15.9994",
    16,
    2,
    "F",
    "Fluorine",
    "18.9984032",
    17,
    2,
    "Ne",
    "Neon",
    "20.1797",
    18,
    2,
    "Na",
    "Sodium",
    "22.98976...",
    1,
    3,
    "Mg",
    "Magnesium",
    "24.305",
    2,
    3,
    "Al",
    "Aluminium",
    "26.9815386",
    13,
    3,
    "Si",
    "Silicon",
    "28.0855",
    14,
    3,
    "P",
    "Phosphorus",
    "30.973762",
    15,
    3,
    "S",
    "Sulfur",
    "32.065",
    16,
    3,
    "Cl",
    "Chlorine",
    "35.453",
    17,
    3,
    "Ar",
    "Argon",
    "39.948",
    18,
    3,
    "K",
    "Potassium",
    "39.948",
    1,
    4,
    "Ca",
    "Calcium",
    "40.078",
    2,
    4,
    "Sc",
    "Scandium",
    "44.955912",
    3,
    4,
    "Ti",
    "Titanium",
    "47.867",
    4,
    4,
    "V",
    "Vanadium",
    "50.9415",
    5,
    4,
    "Cr",
    "Chromium",
    "51.9961",
    6,
    4,
    "Mn",
    "Manganese",
    "54.938045",
    7,
    4,
    "Fe",
    "Iron",
    "55.845",
    8,
    4,
    "Co",
    "Cobalt",
    "58.933195",
    9,
    4,
    "Ni",
    "Nickel",
    "58.6934",
    10,
    4,
    "Cu",
    "Copper",
    "63.546",
    11,
    4,
    "Zn",
    "Zinc",
    "65.38",
    12,
    4,
    "Ga",
    "Gallium",
    "69.723",
    13,
    4,
    "Ge",
    "Germanium",
    "72.63",
    14,
    4,
    "As",
    "Arsenic",
    "74.9216",
    15,
    4,
    "Se",
    "Selenium",
    "78.96",
    16,
    4,
    "Br",
    "Bromine",
    "79.904",
    17,
    4,
    "Kr",
    "Krypton",
    "83.798",
    18,
    4,
    "Rb",
    "Rubidium",
    "85.4678",
    1,
    5,
    "Sr",
    "Strontium",
    "87.62",
    2,
    5,
    "Y",
    "Yttrium",
    "88.90585",
    3,
    5,
    "Zr",
    "Zirconium",
    "91.224",
    4,
    5,
    "Nb",
    "Niobium",
    "92.90628",
    5,
    5,
    "Mo",
    "Molybdenum",
    "95.96",
    6,
    5,
    "Tc",
    "Technetium",
    "(98)",
    7,
    5,
    "Ru",
    "Ruthenium",
    "101.07",
    8,
    5,
    "Rh",
    "Rhodium",
    "102.9055",
    9,
    5,
    "Pd",
    "Palladium",
    "106.42",
    10,
    5,
    "Ag",
    "Silver",
    "107.8682",
    11,
    5,
    "Cd",
    "Cadmium",
    "112.411",
    12,
    5,
    "In",
    "Indium",
    "114.818",
    13,
    5,
    "Sn",
    "Tin",
    "118.71",
    14,
    5,
    "Sb",
    "Antimony",
    "121.76",
    15,
    5,
    "Te",
    "Tellurium",
    "127.6",
    16,
    5,
    "I",
    "Iodine",
    "126.90447",
    17,
    5,
    "Xe",
    "Xenon",
    "131.293",
    18,
    5,
    "Cs",
    "Caesium",
    "132.9054",
    1,
    6,
    "Ba",
    "Barium",
    "132.9054",
    2,
    6,
    "La",
    "Lanthanum",
    "138.90547",
    4,
    9,
    "Ce",
    "Cerium",
    "140.116",
    5,
    9,
    "Pr",
    "Praseodymium",
    "140.90765",
    6,
    9,
    "Nd",
    "Neodymium",
    "144.242",
    7,
    9,
    "Pm",
    "Promethium",
    "(145)",
    8,
    9,
    "Sm",
    "Samarium",
    "150.36",
    9,
    9,
    "Eu",
    "Europium",
    "151.964",
    10,
    9,
    "Gd",
    "Gadolinium",
    "157.25",
    11,
    9,
    "Tb",
    "Terbium",
    "158.92535",
    12,
    9,
    "Dy",
    "Dysprosium",
    "162.5",
    13,
    9,
    "Ho",
    "Holmium",
    "164.93032",
    14,
    9,
    "Er",
    "Erbium",
    "167.259",
    15,
    9,
    "Tm",
    "Thulium",
    "168.93421",
    16,
    9,
    "Yb",
    "Ytterbium",
    "173.054",
    17,
    9,
    "Lu",
    "Lutetium",
    "174.9668",
    18,
    9,
    "Hf",
    "Hafnium",
    "178.49",
    4,
    6,
    "Ta",
    "Tantalum",
    "180.94788",
    5,
    6,
    "W",
    "Tungsten",
    "183.84",
    6,
    6,
    "Re",
    "Rhenium",
    "186.207",
    7,
    6,
    "Os",
    "Osmium",
    "190.23",
    8,
    6,
    "Ir",
    "Iridium",
    "192.217",
    9,
    6,
    "Pt",
    "Platinum",
    "195.084",
    10,
    6,
    "Au",
    "Gold",
    "196.966569",
    11,
    6,
    "Hg",
    "Mercury",
    "200.59",
    12,
    6,
    "Tl",
    "Thallium",
    "204.3833",
    13,
    6,
    "Pb",
    "Lead",
    "207.2",
    14,
    6,
    "Bi",
    "Bismuth",
    "208.9804",
    15,
    6,
    "Po",
    "Polonium",
    "(209)",
    16,
    6,
    "At",
    "Astatine",
    "(210)",
    17,
    6,
    "Rn",
    "Radon",
    "(222)",
    18,
    6,
    "Fr",
    "Francium",
    "(223)",
    1,
    7,
    "Ra",
    "Radium",
    "(226)",
  ];

  var technologies = [
    {
      name: "React",
      icon: react,
    },
    {
      name: "Three JS",
      icon: webgl,
    },
    {
      name: "jQuery",
      icon: jquery,
    },
    {
      name: "Javascript",
      icon: javascript,
    },

    {
      name: "PHP",
      icon: php,
    },

    {
      name: "HTML 5",
      icon: html5,
    },
    {
      name: "Laravel",
      icon: laravel,
    },

    {
      name: "Google Clouds",
      icon: googlecloud,
    },

    {
      name: "Bootstrap",
      icon: bootstrap,
    },
    {
      name: "Material UI",
      icon: materialui,
    },

    {
      name: "Google APIs",
      icon: googlecloud,
    },
    {
      name: "Symfony",
      icon: symfony,
    },
    {
      name: "Zend Framework",
      icon: zend,
    },
    {
      name: "Angular",
      icon: angular,
    },
    {
      name: "MySQL",
      icon: mysql,
    },
    {
      name: "PgSQL",
      icon: pgsql,
    },
    {
      name: "Magento",
      icon: magento,
    },
    {
      name: "iOS",
      icon: ios,
    },
    {
      name: "PgSQL",
      icon: android,
    },
    {
      name: "Swift",
      icon: swift,
    },
    {
      name: "Node",
      icon: node,
    },
    {
      name: "Wordpress",
      icon: wordpress,
    },
    {
      name: "React",
      icon: react,
    },
    {
      name: "Three JS",
      icon: webgl,
    },
    {
      name: "jQuery",
      icon: jquery,
    },
    {
      name: "Javascript",
      icon: javascript,
    },

    {
      name: "Pixi JS",
      icon: pixijs,
    },
    {
      name: "PHP",
      icon: php,
    },
    {
      name: "Phaser",
      icon: phaser,
    },
    {
      name: "HTML 5",
      icon: html5,
    },
    {
      name: "Laravel",
      icon: laravel,
    },

    {
      name: "React Native",
      icon: react,
    },
    {
      name: "React VR",
      icon: react,
    },
    {
      name: "Google Clouds",
      icon: googlecloud,
    },
    {
      name: "AWS",
      icon: aws,
    },

    {
      name: "Bootstrap",
      icon: bootstrap,
    },
    {
      name: "Material UI",
      icon: materialui,
    },
    {
      name: "React",
      icon: react,
    },
    {
      name: "Three JS",
      icon: webgl,
    },
    {
      name: "jQuery",
      icon: jquery,
    },
    {
      name: "Javascript",
      icon: javascript,
    },

    {
      name: "PHP",
      icon: php,
    },

    {
      name: "HTML 5",
      icon: html5,
    },
    {
      name: "Laravel",
      icon: laravel,
    },

    {
      name: "Google Clouds",
      icon: googlecloud,
    },

    {
      name: "Bootstrap",
      icon: bootstrap,
    },
    {
      name: "Material UI",
      icon: materialui,
    },

    {
      name: "Google APIs",
      icon: googlecloud,
    },
    {
      name: "Symfony",
      icon: symfony,
    },
    {
      name: "Zend Framework",
      icon: zend,
    },
    {
      name: "Angular",
      icon: angular,
    },
    {
      name: "MySQL",
      icon: mysql,
    },
    {
      name: "PgSQL",
      icon: pgsql,
    },
    {
      name: "Wordpress",
      icon: wordpress,
    },
    {
      name: "React",
      icon: react,
    },
    {
      name: "Three JS",
      icon: webgl,
    },
    {
      name: "jQuery",
      icon: jquery,
    },
    {
      name: "Javascript",
      icon: javascript,
    },

    {
      name: "Pixi JS",
      icon: pixijs,
    },
    {
      name: "PHP",
      icon: php,
    },
    {
      name: "Phaser",
      icon: phaser,
    },
    {
      name: "HTML 5",
      icon: html5,
    },

    {
      name: "Laravel",
      icon: laravel,
    },
    {
      name: "React Native",
      icon: react,
    },
    {
      name: "React VR",
      icon: react,
    },
    {
      name: "Google Clouds",
      icon: googlecloud,
    },
  ];

  var camera, scene, renderer;
  var controls;
  var objects = [];
  var targets = { table: [], sphere: [], helix: [], grid: [] };
  init();
  animate();
  function init() {
    camera = new THREE.PerspectiveCamera(
      40,
      window.innerWidth / window.innerHeight,
      1,
      10000
    );
    camera.position.z = 2600;
    camera.position.x = 0;
    camera.position.y = 0;

    scene = new THREE.Scene();
    // table
    for (var i = 0, j = 0; j < technologies.length; i += 5, j++) {
      var element = document.createElement("div");
      element.className = "element";
      element.style.backgroundColor =
        "rgba(0,127,127," + (Math.random() * 0.5 + 0.25) + ")";

      if (technologies[j].icon) {
        var symbol = document.createElement("img");
        symbol.className = "symbol";
        symbol.src = technologies[j].icon;
        element.appendChild(symbol);
      }

      var details = document.createElement("div");
      details.className = "details";
      details.innerHTML = technologies[j].name;
      element.appendChild(details);
      var object = new CSS3DObject(element);
      object.position.x = Math.random() * 4000 - 2000;
      object.position.y = Math.random() * 4000 - 2000;
      object.position.z = Math.random() * 4000 - 2000;
      scene.add(object);
      objects.push(object);
      //
      // var object = new THREE.Object3D();
      // object.position.x = table[i + 3] * 140 - 1330;
      // object.position.y = -(table[i + 4] * 180) + 990;
      // targets.table.push(object);
    }
    // var newObjetcs;
    // for (var i = 0, j = 0; i < technologies; i++, j += 5) {
    //   var element = document.createElement("div");
    //   element.className = "element";
    //   element.style.backgroundColor =
    //     "rgba(0,127,127," + (Math.random() * 0.5 + 0.25) + ")";

    //   var symbol = document.createElement("div");
    //   symbol.className = "symbol";
    //   symbol.textContent = "Icon";
    //   element.appendChild(symbol);
    //   var details = document.createElement("div");
    //   details.className = "details";
    //   details.innerHTML = technologies[i].name;
    //   element.appendChild(details);
    //   var object = new CSS3DObject(element);
    //   object.position.x = Math.random() * 4000 - 2000;
    //   object.position.y = Math.random() * 4000 - 2000;
    //   object.position.z = Math.random() * 4000 - 2000;
    //   scene.add(object);
    //   objects.push(object);
    //   //
    //   var object = new THREE.Object3D();
    //   object.position.x = table[j + 3] * 140 - 1330;
    //   object.position.y = -(table[j + 4] * 180) + 990;
    //   targets.table.push(object);
    // }
    // sphere
    var vector = new THREE.Vector3();
    for (let i = 0, l = objects.length; i < l; i++) {
      let phi = Math.acos(-1 + (2 * i) / l);
      let theta = Math.sqrt(l * Math.PI) * phi;
      let object = new THREE.Object3D();
      object.position.setFromSphericalCoords(800, phi, theta);
      vector.copy(object.position).multiplyScalar(2);
      object.lookAt(vector);
      targets.sphere.push(object);
    }
    // helix
    var vector = new THREE.Vector3();
    for (var i = 0, l = objects.length; i < l; i++) {
      var theta = i * 0.175 + Math.PI;
      var y = -(i * 8) + 450;
      var object = new THREE.Object3D();
      object.position.setFromCylindricalCoords(900, theta, y);
      vector.x = object.position.x * 2;
      vector.y = object.position.y;
      vector.z = object.position.z * 2;
      object.lookAt(vector);
      targets.helix.push(object);
    }
    // grid
    for (var i = 0; i < objects.length; i++) {
      var object = new THREE.Object3D();
      object.position.x = (i % 5) * 400 - 800;
      object.position.y = -(Math.floor(i / 5) % 5) * 400 + 800;
      object.position.z = Math.floor(i / 25) * 1000 - 2000;
      targets.grid.push(object);
    }
    //
    renderer = new CSS3DRenderer();
    renderer.setSize(500, 400);
    canvas.appendChild(renderer.domElement);
    //
    controls = new MouseControls(camera);
    controls.rotateSpeed = 0.05;
    controls.noZoom = true;
    controls.addEventListener("change", render);

    transform(targets.sphere, 2000);
    //
    window.addEventListener("resize", onWindowResize, false);
  }
  function transform(targets, duration) {
    TWEEN.removeAll();
    for (var i = 0; i < objects.length; i++) {
      var object = objects[i];
      var target = targets[i];
      new TWEEN.Tween(object.position)
        .to(
          { x: target.position.x, y: target.position.y, z: target.position.z },
          Math.random() * duration + duration
        )
        .easing(TWEEN.Easing.Exponential.InOut)
        .start();
      new TWEEN.Tween(object.rotation)
        .to(
          { x: target.rotation.x, y: target.rotation.y, z: target.rotation.z },
          Math.random() * duration + duration
        )
        .easing(TWEEN.Easing.Exponential.InOut)
        .start();
    }
    new TWEEN.Tween(this)
      .to({}, duration * 2)
      .onUpdate(render)
      .start();
  }
  function onWindowResize() {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(canvas.clientWidth, window.innerHeight);
    render();
  }
  function animate() {
    // camera.position.x = radius * Math.cos(angle);
    // camera.position.z = radius * Math.sin(angle);
    // angle += 0.001;
    requestAnimationFrame(animate);
    TWEEN.update();
    controls.update();
  }
  function render() {
    renderer.render(scene, camera);
  }

  return {
    render: animate,
    onResize: onWindowResize,
    //addEventListeners,
    //removeEventListeners
  };
};
