import React, { Component } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import LeftSidebar from "./components/Sidebars/LeftSidebar";
import RightSidebar from "./components/Sidebars/RightSidebar";
import styled from "styled-components";
import MenuPageContainer from "./containers/MenuPageContainer";
import Routes from "./routes/Routes";
import "./App.css";
import Home from "./views/Home";

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (orientation: portrait) {
    flex-direction:column;
  }
}
`;

const LogoPortrait = styled.div`
  display: none;
  @media (orientation: portrait) {
    display: flex;
    position: absolute;
    margin: 1.3rem;
    flex-direction: column;
  }
  img {
    width: 84px;
  }
  h3 {
    color: #fff;
    letter-spacing: -1px;
  }
`;

const ContentContainer = styled.div`
  flex: 1;
  height: 100vh;
  position: relative;
  background-color: black;
  overflow: hidden;
`;

class App extends Component {
  render() {
    return (
      <MainContainer>
        <LeftSidebar />
        <ContentContainer>
          <LogoPortrait>
            <img
              src={require("./assets/qdev-small.png")}
              alt="QDev Digital Logo"
            />
            <h3>QDEV DIGITAL</h3>
          </LogoPortrait>
          <Home />
        </ContentContainer>
        <RightSidebar />
      </MainContainer>
    );
  }
}

export default App;
