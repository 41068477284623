import React, { Component } from "react";

import animationHandler from "./three/animationHandler";

class TecnologiesAnimation extends Component {
  constructor(props) {
    super(props);
    this.animationLoaded = false;
    this.animationHandler = null;
  }
  componentDidMount() {
    this.animationHandler = animationHandler(this.threeRootElement);
    this.animationLoaded = true;
    this.animationHandler.startAnimation();
  }

  componentWillUnmount() {
    this.animationHandler.stopAnimation();
  }

  render() {
    return (
      <div
        className="home-animation"
        style={{ width: "100%", height: "100vh" }}
        ref={element => (this.threeRootElement = element)}
      />
    );
  }
}

export default TecnologiesAnimation;
