import React from "react";
import styled from "styled-components";
import MenuLinkContainer from "../../containers/MenuLinkContainer";

const LSContainer = styled.section`
  width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 16px;
  position: relative;
  font-family: "Oswald", sans-serif;
  @media (orientation: portrait) {
    display: none;
  }

  .logo {
    object-fit: contain;
    margin-left: 7px;
    position: absolute;
    bottom: 22px;
    left: 4px;
  }

  img {
    width: 90%;
    margin-left: 4px;
  }

  h3 {
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 1.1px;
    opacity: 0.4;
    margin-top: -1px;
    margin-left: 1px;
  }
`;

const LeftSidebar = (props) => {
  return (
    <LSContainer>
      <div className="logo">
        <img
          src={require("../../assets/qdev-small.png")}
          alt="QDev Digital Logo"
        />
        <h3>QDEV DIGITAL</h3>
      </div>
      <MenuLinkContainer />
    </LSContainer>
  );
};

export default LeftSidebar;
