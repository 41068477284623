import React from "react";
import ServicesAnimation from "../components/animations/ServicesAnimation/ServicesAnimation";
import styled from "styled-components";
import { TweenMax } from "gsap/all";
import { Transition } from "react-transition-group";
import PerspectiveContent from "../components/PerspectiveContent/PerspectiveContent";
import imgTree from "./images/tree.png";

const ServicesHolder = styled.div`
  position: absolute;
  width: 100%;
  height: 100vh;
  color: white;
`;

const Heading = styled.h1``;

const Description = styled.div``;

const Services = (props) => {
  return (
    <div>
      <PerspectiveContent>
        <h1>THis is a test</h1>
        <article>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin ut
          elementum enim. Vestibulum in arcu facilisis, ultricies nisi a,
          finibus arcu. Maecenas molestie risus non ligula efficitur faucibus.
          Morbi iaculis placerat mi id faucibus. Class aptent taciti sociosqu ad
          litora torquent per conubia nostra, per inceptos himenaeos. Morbi eu
          nunc sagittis, molestie sem eget, dignissim justo. Sed euismod felis
          libero, quis blandit risus bibendum quis. Curabitur molestie lobortis
          eros, vitae lobortis mi egestas vitae. Pellentesque tincidunt mollis
          quam vitae elementum. Phasellus venenatis elit et est commodo
          interdum. Aliquam turpis elit, cursus sit amet lacus id, pretium
          vestibulum velit. Pellentesque habitant morbi tristique senectus et
          netus et malesuada fames ac turpis egestas. Sed in erat ac nulla
          varius fringilla. Curabitur ut libero rutrum, gravida nisi in,
          pharetra neque. Praesent nec risus mauris. Sed maximus venenatis
          tortor id cursus.
        </article>
        <img src={imgTree} className="inner-element" alt="pic" />
      </PerspectiveContent>
      <ServicesAnimation />
    </div>
  );
};

export default Services;
